import React from 'react';
import { graphql } from 'gatsby';
import { SignedIn } from '@clerk/clerk-react';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ThemeQuestions from '../components/ThemeQuestions';
import LoginForm from '../components/LoginForm';

const ThemeTemplate = ({
  data: {
    contentfulSiteData: { siteName },
    contentfulTheme: {
      contentful_id,
      title,
      heading,
      metaDescription,
      bannerText: { bannerText },
      bannerVideo,
      questions,
    },
  },
}) => (
  <Layout title={title} description={metaDescription} siteName={siteName}>
    <main>
      <Banner
        overline="The MEE Curriculum"
        heading={heading || title}
        text={bannerText}
        video={bannerVideo}
        isTheme={true}
        addMargin={true}
      />
      <SignedIn>
        <ThemeQuestions themeId={contentful_id} items={questions} />
      </SignedIn>
      <LoginForm />
    </main>
  </Layout>
);

export const ThemeTemplateQuery = graphql`
  query ThemeTemplateQuery($id: String!) {
    contentfulSiteData {
      siteName
    }
    contentfulTheme(id: { eq: $id }) {
      contentful_id
      title
      heading {
        raw
      }
      metaDescription
      bannerText {
        bannerText
      }
      bannerVideo {
        ...VideoFragment
      }
      questions {
        id
        contentful_id
        title
        length
        keyConcepts
        year
        keyText {
          keyText
        }
        mainAssessment
        ncLinks {
          raw
        }
        sdgLinks {
          raw
        }
        keyIdeas {
          raw
        }
        lessons {
          id
          contentful_id
          title
          activities {
            raw
          }
          furtherOpportunities {
            raw
          }
        }
      }
    }
  }
`;

export default ThemeTemplate;
