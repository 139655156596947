import React, { useContext, useState } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  brandColours,
  fontSize,
  visuallyHidden,
  fontWeights,
} from '../styles';
import { Container, HtmlContent } from './ui';
import { getQuestionStatus } from '../utils';
import { SiteContext } from '../context/SiteContext';
import ThemeLessons from './ThemeLessons';

const StyledThemeQuestions = styled.section`
  margin-top: -50px;
`;

const StyledItems = styled.div`
  background-color: ${brandColours.nonary};
  border: 1px solid ${brandColours.duodenary};
`;

const StyledItem = styled.article`
  border-top: 1px solid ${brandColours.duodenary};

  &:first-child {
    border-top: 0;
  }
`;

const StyledHeader = styled.header`
  position: relative;
  display: grid;
  gap: 20px;
  padding: 18px 50px 18px 70px;
  cursor: pointer;

  ${minBreakpointQuery.tiny`
    gap: 30px;
    padding: 22px 60px 22px 90px;
  `}

  ${minBreakpointQuery.small`
    padding: 26px 70px 26px 100px;
  `}

  ${minBreakpointQuery.medium`
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.large`
    gap: 40px;
    padding-right: 90px;
  `}

  ${({ active }) => {
    if (active) {
      return css`
        background-color: ${brandColours.tertiary};
      `;
    }
  }}

  &:after {
    content: '+';
    position: absolute;
    top: 50%;
    right: 10px;
    padding: 10px;
    ${fontSize(26)};
    transform: translateY(-50%);

    ${minBreakpointQuery.tiny`
      right: 15px;
    `}

    ${minBreakpointQuery.small`
      right: 20px;
    `}
  }
`;

const StyledTick = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 50px;
  background: ${({ complete }) => (complete ? brandColours.tertiary : 'none')};
  border: none;
  border-right: 1px solid ${brandColours.duodenary};

  ${minBreakpointQuery.tiny`
    width: 60px;
  `}

  ${minBreakpointQuery.small`
    width: 70px;
  `}

  &:before {
    content: '';
    display: block;
    height: 22px;
    width: 14px;
    border: 6px solid ${brandColours.secondary};
    border-top: none;
    border-left: none;
    opacity: ${({ complete }) => (complete ? 1 : 0.2)};
    transform: rotate(45deg);
  }

  span {
    ${visuallyHidden()};
  }
`;

const StyledHeading = styled.h3`
  ${fontSize(17)};

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(19)};
  `}
`;

const StyledStatusDownload = styled.div`
  display: flex;

  ${maxBreakpointQuery.small`
    flex-direction: column;
    align-items: start;
    gap: 15px;
  `}

  ${minBreakpointQuery.small`
    align-items: center;
    gap: 20px;
  `}

  ${minBreakpointQuery.medium`
    justify-content: end;
    gap: 30px;
  `}

  ${minBreakpointQuery.large`
    gap: 40px;
  `}
`;

const StyledStatus = styled.p`
  ${fontSize(14)};

  span {
    font-weight: ${fontWeights.bold};
  }
`;

const StyledInner = styled.div`
  border-top: 1px solid ${brandColours.duodenary};

  ${({ display }) => {
    if (!display) {
      return css`
        ${visuallyHidden()};
      `;
    }
  }}
`;

const StyledDetailsInformation = styled.div`
  ${minBreakpointQuery.medium`
    display: grid;
    grid-template-columns: 1fr 1.85fr;
  `}
`;

const StyledDetails = styled.div`
  ${minBreakpointQuery.medium`
    border-right: 1px solid ${brandColours.duodenary};
  `}
`;

const StyledDetail = styled.p`
  padding: 12px 20px;
  ${fontSize(14)};
  line-height: 1.2;
  border-bottom: 1px solid ${brandColours.duodenary};

  ${minBreakpointQuery.tiny`
    padding: 15px 30px;
  `}

  &:last-child {
    ${minBreakpointQuery.medium`
      border-bottom: none;
    `}
  }
`;

const StyledInformation = styled.div``;

const StyledInfoInner = styled.div`
  ${({ sdgLinks }) => {
    if (sdgLinks) {
      return css`
        display: grid;

        ${minBreakpointQuery.small`
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        `}
      `;
    }
  }}
`;

const StyledContent = styled.div`
  padding: 12px 20px;
  border-top: 1px solid ${brandColours.duodenary};

  ${minBreakpointQuery.tiny`
    padding: 15px 30px;
  `}

  &:first-child {
    border-top: 0;
  }

  ${({ isSdg }) => {
    if (isSdg) {
      return css`
        ${minBreakpointQuery.small`
          border-top: none;
          border-left: 1px solid ${brandColours.duodenary};
        `}
      `;
    }
  }}
`;

const StyledSubheading = styled.h3`
  margin-bottom: 8px;
  ${fontSize(14)};
`;

const ThemeQuestions = ({ themeId, items }) => {
  const { completeLesson, progress, userId } = useContext(SiteContext);

  const [activeItem, setActiveItem] = useState(0);

  const keyIdeaOptions = {
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => <ol>{children}</ol>,
    },
  };

  return (
    <StyledThemeQuestions>
      <Container>
        <StyledItems>
          {Object.keys(progress).length > 0 &&
            items.map(
              (
                {
                  contentful_id,
                  id,
                  title,
                  length,
                  keyConcepts,
                  year,
                  keyText,
                  mainAssessment,
                  ncLinks,
                  sdgLinks,
                  keyIdeas,
                  lessons,
                },
                i
              ) => {
                const questionStatus = getQuestionStatus(progress, themeId, {
                  contentful_id,
                  lessons,
                });
                const display = activeItem === i;

                return (
                  <StyledItem key={id}>
                    <StyledHeader
                      onClick={() => setActiveItem(display ? undefined : i)}
                      active={display}
                    >
                      <StyledTick complete={questionStatus.complete}>
                        <span>
                          {questionStatus.complete
                            ? 'Complete'
                            : 'Not Complete'}
                        </span>
                      </StyledTick>
                      <StyledHeading>{title}</StyledHeading>
                      <StyledStatusDownload>
                        <StyledStatus>
                          {questionStatus.complete ? (
                            <span>Complete</span>
                          ) : (
                            <>
                              <span>{questionStatus.lessonCompleteCount}</span>{' '}
                              of {lessons.length} lessons complete
                            </>
                          )}
                        </StyledStatus>
                      </StyledStatusDownload>
                    </StyledHeader>
                    <StyledInner display={display}>
                      <StyledDetailsInformation>
                        <StyledDetails>
                          <StyledDetail>
                            <strong>Length:</strong> {length}
                          </StyledDetail>
                          {keyConcepts && (
                            <StyledDetail>
                              <strong>Key Concept(s):</strong> {keyConcepts}
                            </StyledDetail>
                          )}
                          <StyledDetail>
                            <strong>Key Stage:</strong> {year}
                          </StyledDetail>
                          {keyText && (
                            <StyledDetail>
                              <strong>Key Text:</strong> {keyText.keyText}
                            </StyledDetail>
                          )}
                          {mainAssessment && (
                            <StyledDetail>
                              <strong>Main Assessment:</strong> {mainAssessment}
                            </StyledDetail>
                          )}
                        </StyledDetails>
                        <StyledInformation>
                          <StyledInfoInner sdgLinks={sdgLinks}>
                            {ncLinks && (
                              <StyledContent>
                                <StyledSubheading>NC Links:</StyledSubheading>
                                <HtmlContent richText={ncLinks} />
                              </StyledContent>
                            )}
                            {sdgLinks && (
                              <StyledContent isSdg={true}>
                                <StyledSubheading>SDG Links:</StyledSubheading>
                                <HtmlContent richText={sdgLinks} />
                              </StyledContent>
                            )}
                          </StyledInfoInner>
                          {keyIdeas && (
                            <StyledContent>
                              <StyledSubheading>Key Ideas:</StyledSubheading>
                              <HtmlContent
                                richText={keyIdeas}
                                options={keyIdeaOptions}
                              />
                            </StyledContent>
                          )}
                        </StyledInformation>
                      </StyledDetailsInformation>
                      <ThemeLessons
                        items={lessons}
                        progress={progress}
                        themeId={themeId}
                        questionId={contentful_id}
                        userId={userId}
                        completeLesson={completeLesson}
                      />
                    </StyledInner>
                  </StyledItem>
                );
              }
            )}
        </StyledItems>
      </Container>
    </StyledThemeQuestions>
  );
};

export default ThemeQuestions;
