import React from 'react';
import styled from 'styled-components';
import {
  brandColours,
  fontWeights,
  maxBreakpointQuery,
  minBreakpointQuery,
  standardColours,
  visuallyHidden,
} from '../styles';
import { HtmlContent } from './ui';
import { isLessonComplete } from '../utils';

const StyledThemeLessons = styled.section``;

const StyledLesson = styled.div`
  border-top: 1px solid ${brandColours.duodenary};

  ${minBreakpointQuery.smedium`
    display: grid;
    grid-template-columns: 100px 1fr;
  `}
`;

const StyledLessonNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: ${standardColours.white};
  font-weight: ${fontWeights.semibold};

  ${minBreakpointQuery.smedium`
    border-right: 1px solid ${brandColours.duodenary};
  `}
`;

const StyledContent = styled.div``;

const StyledHeader = styled.div`
  position: relative;
  padding-right: 80px;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: ${standardColours.white};

  ${maxBreakpointQuery.smedium`
    border-top: 1px solid ${brandColours.duodenary};
  `}

  ${minBreakpointQuery.medium`
    padding-top: 20px;
    padding-bottom: 20px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 30px;
    padding-bottom: 30px;
  `}
`;

const StyledButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid ${brandColours.duodenary};
  padding: 0;
`;

const StyledTick = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 50px;
  height: 100%;
  background: ${({ complete }) => (complete ? brandColours.tertiary : 'none')};
  border: none;

  ${minBreakpointQuery.tiny`
    width: 60px;
  `}

  ${minBreakpointQuery.small`
    width: 70px;
  `}

  &:before {
    content: '';
    display: 'block';
    height: 22px;
    width: 14px;
    border: 6px solid ${brandColours.secondary};
    border-top: none;
    border-left: none;
    opacity: ${({ complete }) => (complete ? 1 : 0.2)};
    transform: rotate(45deg);
  }

  span {
    ${visuallyHidden()};
  }
`;

const StyledActivities = styled.div`
  border-top: 1px solid ${brandColours.duodenary};

  ${minBreakpointQuery.smedium`
    display: flex;
  `}
`;

const StyledActivitiesHeadingContainer = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;

  ${minBreakpointQuery.smedium`
    max-width: 40px;
    min-height: 140px;
  `}
`;

const StyledActivitiesHeading = styled.h4`
  ${minBreakpointQuery.smedium`
    transform: rotate(-90deg) translateY(10px) translateX(calc(-50% - 2px));
    transform-origin: center left;
  `}
`;

const StyledHtmlContent = styled(HtmlContent)`
  padding: 15px;

  ${maxBreakpointQuery.smedium`
    border-top: 1px solid ${brandColours.duodenary};
  `}

  ${minBreakpointQuery.smedium`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid ${brandColours.duodenary};
    margin-left: 10px;
  `}

  a {
    color: ${standardColours.black};
    border-bottom: 1px solid ${standardColours.black};
    overflow-wrap: anywhere;
  }
`;

const StyledFurtherOpportunities = styled.div`
  border-top: 1px solid ${brandColours.duodenary};

  ${minBreakpointQuery.smedium`
    display: flex;
    align-items: center;
  `}
`;

const StyledFurtherOpportunitiesHeading = styled.h4`
  padding: 15px;

  ${minBreakpointQuery.smedium`
    flex-shrink: 0;
  `}
`;

const ThemeLessons = ({
  items,
  progress,
  themeId,
  questionId,
  userId,
  completeLesson,
}) => {
  return (
    <StyledThemeLessons>
      {items.map(
        ({ contentful_id, id, title, activities, furtherOpportunities }, i) => {
          const lessonComplete = isLessonComplete(
            progress,
            themeId,
            questionId,
            contentful_id
          );
          return (
            <StyledLesson key={id}>
              <StyledLessonNumber>
                <p>{i + 1}</p>
              </StyledLessonNumber>
              <StyledContent>
                <StyledHeader>
                  <h3>{title}</h3>
                  <StyledButton
                    disabled={lessonComplete}
                    complete={lessonComplete}
                    onClick={() =>
                      completeLesson(userId, themeId, questionId, contentful_id)
                    }
                  >
                    <StyledTick complete={lessonComplete}>
                      <span>
                        {lessonComplete ? 'Complete' : 'Not Complete'}
                      </span>
                    </StyledTick>
                  </StyledButton>
                </StyledHeader>
                {activities && (
                  <StyledActivities>
                    <StyledActivitiesHeadingContainer>
                      <StyledActivitiesHeading>
                        Activities
                      </StyledActivitiesHeading>
                    </StyledActivitiesHeadingContainer>
                    <StyledHtmlContent richText={activities} />
                  </StyledActivities>
                )}
                {furtherOpportunities && (
                  <StyledFurtherOpportunities>
                    <StyledFurtherOpportunitiesHeading>
                      Further Opportunities:
                    </StyledFurtherOpportunitiesHeading>
                    <StyledHtmlContent richText={furtherOpportunities} />
                  </StyledFurtherOpportunities>
                )}
              </StyledContent>
            </StyledLesson>
          );
        }
      )}
    </StyledThemeLessons>
  );
};

export default ThemeLessons;
